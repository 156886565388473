import { ChangeEvent, useEffect, useState } from 'react';
import { Controller, useFormContext } from 'react-hook-form';
import { useTranslation } from 'react-i18next';

import { Button, Icon } from '@socialbrothers/components/UI';
import { Color, FileInputAction } from '@socialbrothers/constants';
import { getFileURL, isFile } from '@socialbrothers/utils';

import styles from './FileInput.module.scss';
import { FileInputProps } from './FileInput.props';

const FileInput = ({
  name,
  onDelete,
  hasThumbnail = true,
  isDocument,
  remoteUrl,
  ...props
}: FileInputProps) => {
  const { control, watch } = useFormContext();
  const value = watch(name);
  const { t } = useTranslation();

  const fileName = value?.fileName;

  const [previewUrl, setPreviewUrl] = useState<string | null>(null);

  useEffect(() => {
    if (!value) {
      return;
    }

    if (typeof value?.file === 'string') {
      setPreviewUrl(getFileURL(value.file));
    } else if (isFile(value.file)) {
      setPreviewUrl(URL.createObjectURL(value.file));
    } else if (typeof value.path === 'string') {
      setPreviewUrl(value.path);
    } else {
      setPreviewUrl(null);
    }
  }, [value]);

  return (
    <Controller
      name={name}
      control={control}
      defaultValue={null}
      render={({ field: { onChange } }) => {
        const onChangleHandler = (event: ChangeEvent<HTMLInputElement>) => {
          const file = event.target.files?.[0];

          if (file) {
            onChange({
              action: FileInputAction.WRITE,
              file: file,
              fileName: file.name,
            });
          }
        };

        return (
          <div className={styles.FileInput} {...props}>
            <label className={styles.FileInput__Input} htmlFor={name}>
              <input onChange={onChangleHandler} {...props} type="file" name={name} id={name} />

              <div className={styles.FileInput__Name}>{fileName || '...'}</div>

              <div className={styles.FileInput__Button}>
                {fileName ? t('FORMS.FILE.LABEL_CHANGE') : t('FORMS.FILE.LABEL_CHOOSE')}
              </div>
            </label>

            {hasThumbnail && !!previewUrl && (
              <div className={styles.Preview}>
                <div className={styles.Preview__Item}>
                  <a
                    className={styles.Preview__Link}
                    href={previewUrl}
                    target="_blank"
                    rel="noreferrer"
                  >
                    {isDocument ? (
                      <Icon className={styles.Preview__PDF} icon="file-pdf" />
                    ) : (
                      <img className={styles.Preview__File} src={previewUrl} alt="Preview" />
                    )}
                  </a>

                  <Button
                    type="button"
                    onClick={() => {
                      onChange(null);
                      setPreviewUrl(null);
                    }}
                    className={styles.Preview__Delete}
                    color={Color.DANGER}
                    icon="trash-alt"
                  />
                </div>
              </div>
            )}
          </div>
        );
      }}
    />
  );
};

export default FileInput;
