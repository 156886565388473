import { BaseModel } from '@socialbrothers/constants';

import { toFormData } from '@Utils/ServiceUtils';

import ResourceService from './ResourceService';

class ResourceServiceFormData<T extends BaseModel> extends ResourceService<T> {
  create = async (data: Partial<T>) => {
    const response = await this.client.post<T>(this.endpoint, toFormData(data));

    return response.data;
  };

  update = async (id: string, data: Partial<T>) => {
    const response = await this.client.put<T>(
      `${this.endpoint}/${id}`,
      toFormData({
        id,
        ...data,
      }),
    );

    return response.data;
  };
}

export default ResourceServiceFormData;
