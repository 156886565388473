import { ResourceService } from '@socialbrothers/services';

import { IBuildingTypeCompany } from '@Services/BuildingTypeCompany';
import { AppService } from '@Services/index';

export class BuildingTypeCompanyServiceImplementation extends ResourceService<IBuildingTypeCompany> {}

export const BuildingTypeCompanyService = new BuildingTypeCompanyServiceImplementation(
  AppService,
  '/buildings',
);
