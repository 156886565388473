import { useQuery } from 'react-query';

import { ResourceInterface } from '@socialbrothers/services';
import { isCreate } from '@socialbrothers/utils';

export function useResource<T = any>(service: ResourceInterface, id?: string) {
  return useQuery<T>(
    [service.endpoint, id],
    !isCreate(id) && id ? () => service.getOne(id) : async () => null,
  );
}
