import { useTranslation } from 'react-i18next';

import { Page } from '@socialbrothers/components/Layout';
import { Button, Card } from '@socialbrothers/components/UI';
import { Color } from '@socialbrothers/constants';

import { CustomerTable } from '@Components/tables';

export const CustomerOverviewPage = () => {
  const { t } = useTranslation();

  const buttons = (
    <Button icon="plus" to="/customers/create" color={Color.SECONDARY}>
      {t('GLOBAL.CREATE_MODEL', { name: t('CUSTOMER.SINGLE') })}
    </Button>
  );

  return (
    <Page title={t('CUSTOMER.PLURAL')} buttons={buttons}>
      <Card title={t('CUSTOMER.PLURAL')}>
        <CustomerTable />
      </Card>
    </Page>
  );
};
