import { forwardRef } from 'react';
import { useFormContext } from 'react-hook-form';

import styles from './MultilineInput.module.scss';
import { MultilineInputProps } from './MultilineInput.props';

const MultilineInput = ({ ...props }: MultilineInputProps) => {
  const { register } = useFormContext();

  return (
    <textarea className={styles.MultilineInput} {...(props.name ? register(props.name) : {})} />
  );
};

export default forwardRef(MultilineInput);
