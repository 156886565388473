import { useFormContext } from 'react-hook-form';

import { NumberInputProps } from './NumberInput.props';

const NumberInput = ({ name = `${Date.now()}`, ...props }: NumberInputProps) => {
  const { register } = useFormContext();

  return <input type="number" step="any" {...props} {...register(name)} />;
};

export default NumberInput;
