import cn from 'classnames';

import styles from './AuthWrapper.module.scss';
import { AuthWrapperProps } from './AuthWrapper.props';

const AuthWrapper = ({ title, children, imageUri, ...props }: AuthWrapperProps) => {
  return (
    <div className={styles.AuthWrapper} {...props}>
      <div className="container">
        <div className={cn('card', styles.Wrapper)}>
          <div className={styles.Column}>
            <img src={imageUri} className={cn(styles.Image, 'fill')} alt="Login" />
          </div>

          <div className={styles.Column}>
            <div className={styles.Body}>
              <h1 className={cn('h1', 'text-center', 'mb-4')}>{title}</h1>
              {children}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default AuthWrapper;
