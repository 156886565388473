import { Instance, types } from 'mobx-state-tree';

import { getIsMobile } from '@Utils/ClientUtils';

import SidebarModel from './SidebarModel';

const ThemeStore = types
  .model('ThemeStore', {
    isMobile: getIsMobile(),
    Sidebar: SidebarModel,
  })
  .actions((self) => ({
    setIsMobile(isMobile: boolean) {
      self.isMobile = isMobile;
    },
  }));

export interface ThemeStoreInstance extends Instance<typeof ThemeStore> {}
export default ThemeStore;
