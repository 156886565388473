import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';

import { Form } from '@socialbrothers/components/Containers';
import { Yup } from '@socialbrothers/utils';

import { AcousticValuesRepeater } from '@Components/inputs';
import { acousticValuesSchema } from '@Constants/schemas';
import { ProductService } from '@Services/Product';

export const productSchema = acousticValuesSchema('acousticValues').shape({
  name: Yup.string().required(),
  type: Yup.string().required(),
  application: Yup.string().required(),
  brand: Yup.string().required(),
  image: Yup.mixed(),
  description: Yup.string().required(),
  length: Yup.string().required(),
  width: Yup.string().required(),
});

export const ProductForm = () => {
  const { id } = useParams<{ id: string }>();
  const { t } = useTranslation();

  return (
    <Form.Resource service={ProductService} label={t('PRODUCT.SINGLE')} id={id}>
      <Form.Layout.Field translationKey="PRODUCT.LABELS.NAME">
        <Form.Input.Text name="name" />
      </Form.Layout.Field>

      <Form.Layout.Field translationKey="PRODUCT.LABELS.TYPE">
        <Form.Input.Text name="type" />
      </Form.Layout.Field>

      <Form.Layout.Field translationKey="PRODUCT.LABELS.APPLICATION">
        <Form.Input.Text name="application" />
      </Form.Layout.Field>

      <Form.Layout.Field translationKey="PRODUCT.LABELS.BRAND">
        <Form.Input.Text name="brand" />
      </Form.Layout.Field>

      <Form.Layout.Field translationKey="PRODUCT.LABELS.IMAGE">
        <Form.Input.File name="image" accept="image/jpeg, image/png" />
      </Form.Layout.Field>

      <Form.Layout.Field translationKey="PRODUCT.LABELS.DESCRIPTION">
        <Form.Input.Multiline name="description" />
      </Form.Layout.Field>

      <AcousticValuesRepeater
        entryName={t('PRODUCT.LABELS.ACOUSTICVALUES')}
        source="acousticValues"
        label={t('PRODUCT.LABELS.ACOUSTICVALUES')}
      />

      <Form.Layout.Field translationKey="PRODUCT.LABELS.LENGTH">
        <Form.Input.Number name="length" />
      </Form.Layout.Field>

      <Form.Layout.Field translationKey="PRODUCT.LABELS.WIDTH">
        <Form.Input.Number name="width" />
      </Form.Layout.Field>
    </Form.Resource>
  );
};
