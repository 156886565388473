import { observer } from 'mobx-react';
import { useTranslation } from 'react-i18next';
import { useIsFetching } from 'react-query';

import { Spinner } from '@socialbrothers/components/UI';

import styles from './Footer.module.scss';

const Footer = () => {
  const { t } = useTranslation();
  const isFetching = useIsFetching();

  return (
    <div className={styles.Footer}>
      Copyright &copy; {t('GLOBAL.SITE_NAME')} {new Date().getFullYear()}
      {!!isFetching && <Spinner className={styles.Spinner} size={20} />}
    </div>
  );
};
export default observer(Footer);
