import ACCOUNT from './account.json';
import AUTHENTICATION from './authentication.json';
import COMPONENTS from './components.json';
import FORGOT_PASSWORD from './forgotPassword.json';
import FORMS from './forms.json';
import GLOBAL from './global.json';
import RESET_PASSWORD from './resetPassword.json';
import RESOURCE from './resource.json';
import TABLE from './table.json';
import USERS from './users.json';
import VALIDATION from './validation.json';

const data = {
  translation: {
    AUTHENTICATION,
    GLOBAL,
    TABLE,
    RESOURCE,
    FORMS,
    VALIDATION,
    FORGOT_PASSWORD,
    ACCOUNT,
    COMPONENTS,
    RESET_PASSWORD,
    USERS,
  },
};

export default data;
