import { useTranslation } from 'react-i18next';
import { useMutation } from 'react-query';
import { useQueryClient } from 'react-query';
import { toast } from 'react-toastify';

import { ButtonWithPopup, Table } from '@socialbrothers/components/Containers';
import { Button } from '@socialbrothers/components/UI';
import { Color } from '@socialbrothers/constants';

import { BlogService, IBlog } from '@Services/Blog';
import { BuildingTypeService } from '@Services/BuildingType';

export const BuildingTypeBlogTable = ({
  blogs,
  onEdit,
  buildingTypeId,
}: {
  blogs: IBlog[];
  onEdit: (id: string) => void;
  buildingTypeId: string;
}) => {
  const { t } = useTranslation();

  const queryClient = useQueryClient();

  const mutateDelete = useMutation((deleteId: string) => BlogService.delete(deleteId), {
    onSuccess: () => {
      queryClient.invalidateQueries(BlogService.endpoint);
      queryClient.invalidateQueries([BuildingTypeService.endpoint, buildingTypeId]);

      toast.success(t('GLOBAL.DELETED_SUCCESSFUL', { name: `${t('BLOG.SINGLE')}` }));
    },
    onError: () => {
      toast.error(t('GLOBAL.DELETED_UNSUCCESSFUL', { name: `${t('BLOG.SINGLE')}` }));
    },
  });

  return (
    <>
      <Table.Base<IBlog> data={blogs}>
        <Table.Field.Text sortable source="name" label={t('BLOG.LABELS.NAME')} />

        <Table.Field.Text source="description" sortable label={t('GLOBAL.LABELS.CREATEDAT')} />

        <Table.Action.Field
          render={(res) => {
            return (
              <>
                <Button
                  icon="edit"
                  onClick={(e: any) => {
                    e.stopPropagation();
                    onEdit(res.id);
                  }}
                  color={Color.PRIMARY}
                />

                <ButtonWithPopup
                  popup={{
                    title: t('BLOG.DELETE_MODAL.TITLE'),
                  }}
                  button={{
                    icon: 'trash',
                    color: Color.DANGER,
                  }}
                  withClose
                  submit={{
                    isLoading: mutateDelete.isLoading,
                    children: t('BLOG.DELETE_MODAL.SUBMIT'),
                    color: Color.DANGER,
                    onClick: () => {
                      return mutateDelete.mutateAsync(res.id);
                    },
                  }}
                >
                  {t('BLOG.DELETE_MODAL.BODY')}
                </ButtonWithPopup>
              </>
            );
          }}
        />
      </Table.Base>
    </>
  );
};
