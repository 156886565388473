import { decodeJwt } from 'jose';

import { Api, Role } from '@socialbrothers/constants';
import StorageHelper from '@socialbrothers/helpers/StorageHelper';

export const getJwt = () => {
  const tokens = StorageHelper.getAccessToken(Api.MAIN);

  return tokens && decodeJwt(tokens.accessToken);
};

export const hasRoles = (...roles: Array<keyof typeof Role>) => {
  const jwtRole = getJwt()?.role;

  const jwtRoles = Array.isArray(jwtRole) ? jwtRole : [jwtRole];

  return roles.every((role) => jwtRoles.includes(role));
};
