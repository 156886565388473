import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';

import { Form } from '@socialbrothers/components/Containers';

import { AcousticValueCategories } from '@Constants/interfaces';

import { AcousticValueRepeaterProps } from './AcousticValuesRepeater.props';

export const AcousticValuesRepeater = ({
  label,
  entryName,
  source,
}: AcousticValueRepeaterProps) => {
  const { t } = useTranslation();

  const acousticValueCategoryNames = useMemo(
    () =>
      Object.values(AcousticValueCategories).map((categoryName) => ({
        value: t(`ENUM.ACOUSTIC_VALUES.CATEGORIES.${categoryName}`),
        key: categoryName,
      })),
    [t],
  );

  return (
    <Form.Layout.Repeater label={label} source={`_acousticValues.${source}`} entryName={entryName}>
      <Form.Layout.Field translationKey="FORMS.ACOUSTIC_VALUES.LABELS.CATEGORY">
        <Form.Input.Select name="category" options={acousticValueCategoryNames} />
      </Form.Layout.Field>

      <Form.Layout.Field translationKey="ENUM.ACOUSTIC_VALUES.KEYS.HZ_125">
        <Form.Input.Number step={0.001} name="HZ_125" />
      </Form.Layout.Field>

      <Form.Layout.Field translationKey="ENUM.ACOUSTIC_VALUES.KEYS.HZ_250">
        <Form.Input.Number step={0.001} name="HZ_250" />
      </Form.Layout.Field>

      <Form.Layout.Field translationKey="ENUM.ACOUSTIC_VALUES.KEYS.HZ_500">
        <Form.Input.Number step={0.001} name="HZ_500" />
      </Form.Layout.Field>

      <Form.Layout.Field translationKey="ENUM.ACOUSTIC_VALUES.KEYS.HZ_1000">
        <Form.Input.Number step={0.001} name="HZ_1000" />
      </Form.Layout.Field>

      <Form.Layout.Field translationKey="ENUM.ACOUSTIC_VALUES.KEYS.HZ_2000">
        <Form.Input.Number step={0.001} name="HZ_2000" />
      </Form.Layout.Field>

      <Form.Layout.Field translationKey="ENUM.ACOUSTIC_VALUES.KEYS.HZ_4000">
        <Form.Input.Number step={0.001} name="HZ_4000" />
      </Form.Layout.Field>
    </Form.Layout.Repeater>
  );
};
