import { useTranslation } from 'react-i18next';

import { Form } from '@socialbrothers/components/Containers';
import { Button, Modal } from '@socialbrothers/components/UI';
import { Color } from '@socialbrothers/constants';
import { useModal } from '@socialbrothers/hooks';

import { ButtonWithPopupProps } from './ButtonWithPopup.props';

export const ButtonWithPopup = ({
  popup,
  submit,
  button,
  children,
  withForm,
  validationSchema,
  initialValues,
  className,
  withClose = true,
}: ButtonWithPopupProps) => {
  const { t } = useTranslation();
  const { onOpen, onClose, isVisible } = useModal();

  const handleOpen = (e: any) => {
    e.stopPropagation();
    onOpen();
  };

  const handleSubmit = async (values?: any) => {
    if (submit && submit.onClick) {
      await submit.onClick(values);
      onClose();
    }
  };

  const handleNoFormSubmit = async () => {
    if (submit && submit.onClick) {
      await submit.onClick(null);
      onClose();
    }
  };

  return (
    <>
      <Button type="button" onClick={handleOpen} {...button} className={className} />

      <Modal title={popup.title} onClose={withClose ? onClose : undefined} isVisible={isVisible}>
        {withForm && (
          <Form.Base
            initialValues={initialValues}
            validationSchema={validationSchema}
            onSubmit={handleSubmit}
          >
            {children}

            <hr />

            <div className="d-flex justify-content-end">
              {withClose && (
                <Button type="button" onClick={onClose} color={Color.GRAY}>
                  {t('RESOURCE.DELETE.MODAL.CANCEL')}
                </Button>
              )}

              {submit && <Button {...submit} onClick={undefined} type="submit" />}
            </div>
          </Form.Base>
        )}

        {!withForm && (
          <>
            {children}

            <hr />

            <div className="d-flex justify-content-end">
              {withClose && (
                <Button type="button" onClick={onClose} color={Color.GRAY}>
                  {t('RESOURCE.DELETE.MODAL.CANCEL')}
                </Button>
              )}

              {submit && <Button {...submit} onClick={handleNoFormSubmit} />}
            </div>
          </>
        )}
      </Modal>
    </>
  );
};
