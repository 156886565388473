import { get } from 'lodash';

import { Field } from '@socialbrothers/constants';

import { RelationFieldProps } from './RelationField.props';

function RelationField<T>({
  source,
  record,
  namePath,
  renderName,
  className,
  options,
  hidden,
  type = 'oto',
  ...props
}: RelationFieldProps<T>) {
  const value = get(record, source);

  if (hidden) {
    return null;
  }

  const getName = () => {
    if (renderName) {
      return renderName(record);
    }

    if (namePath) {
      return get(record, namePath);
    }

    return '';
  };

  return (
    <div className={className} {...props}>
      {type === 'otm' ? value?.map(getName).join(', ') : getName()}
    </div>
  );
}

RelationField.displayName = Field.RELATION_FIELD;

export default RelationField;
