import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';

import { Page } from '@socialbrothers/components/Layout';
import { Card } from '@socialbrothers/components/UI';
import { useResource } from '@socialbrothers/hooks';

import { BuildingTypeForm } from '@Components/forms';
import { BlogSection } from '@Pages/BuildingType/internal/components/BlogSection/BlogSection';
import { BuildingTypeService, IBuildingType } from '@Services/BuildingType';

export const BuildingTypeDetailPage = () => {
  const { t } = useTranslation();
  const { id } = useParams<{ id: string }>();
  const { data } = useResource<IBuildingType>(BuildingTypeService, id);

  const label = data?.building?.name ? data.building?.name : t('BUILDINGTYPE.SINGLE');

  return (
    <Page title={label}>
      <Card title={label}>
        <BuildingTypeForm />
      </Card>

      <Card title={t('BUILDINGTYPE.BLOGS')}>
        <BlogSection blogs={data?.blogs ?? []} buildingTypeId={id ?? ''} />
      </Card>
    </Page>
  );
};
