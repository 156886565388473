import { LocaleLanguageMap } from '@socialbrothers/constants';

import i18n from '@Root/i18n';

export const getTranslation = (name: string, params?: object) => {
  const result = i18n.t(name, { options: params });

  if (result !== name) {
    return result;
  }
};

export const isShortLocale = (locale: string): boolean => {
  return Object.values(LocaleLanguageMap).includes(locale as LocaleLanguageMap);
};
