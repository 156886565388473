import { Yup } from '@socialbrothers/utils';

import { AcousticValueCategories, AcousticValueKeys, AVCategories, AVKeys } from './interfaces';

export const rawAcousticValueSchema = Yup.array()
  .of(
    Yup.object({
      id: Yup.string().required(),
      key: Yup.string().oneOf(Object.values<AVKeys>(AcousticValueKeys)).required(),
      category: Yup.string().oneOf(Object.values<AVCategories>(AcousticValueCategories)).required(),
      value: Yup.number().required(),
    }).required(),
  )
  .required();

export const acousticValueSchema = Yup.object({
  ...Object.fromEntries(
    Object.keys(Object.values<AVKeys>(AcousticValueKeys)).map((key) => [
      key,
      Yup.string().required(),
    ]),
  ),
  category: Yup.string().oneOf(Object.values<AVCategories>(AcousticValueCategories)).required(),
});

export const acousticValuesSchema = (acousticValuesKey: string) =>
  Yup.object({
    [acousticValuesKey]: Yup.array().of(acousticValueSchema),
  });
