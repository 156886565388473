import { useTranslation } from 'react-i18next';
import { useQuery } from 'react-query';

import { Form } from '@socialbrothers/components/Containers';
import { Spinner } from '@socialbrothers/components/UI';
import { Role } from '@socialbrothers/constants';
import { enumToOptions, Yup } from '@socialbrothers/utils';

import { AccountService } from '@Services/AccountService';

import { BaseUserForm } from '../../UserForm';

export const dealerSchema = Yup.object({
  company: Yup.object({ id: Yup.string().required() }),
});

export const UserDealerForm = () => {
  const { isLoading: userMeIsLoading, data: dealerData } = useQuery(
    ['users', 'me'],
    AccountService.getMe,
  );

  const { t } = useTranslation();

  if (userMeIsLoading) {
    return <Spinner />;
  }

  const companyOptions = dealerData
    ? [{ disabled: false, key: dealerData?.company.id, value: dealerData?.company.name }]
    : [];

  return (
    <BaseUserForm validationSchema={dealerSchema}>
      <Form.Layout.Field translationKey="USER.LABELS.ROLES">
        <Form.Input.Select
          disabled
          name="role"
          defaultValue={
            enumToOptions(Role, 'ROLE').find((option) => {
              return option.key === Role.ADVISOR;
            })!.key
          }
          options={enumToOptions(Role, 'ROLE')}
        />
      </Form.Layout.Field>

      <Form.Layout.Field translationKey="USER.LABELS.COMPANY">
        <Form.Input.Select
          disabled
          options={companyOptions}
          defaultValue={dealerData?.company.id}
          name="company.id"
          placeholder={t('USER.PLACEHOLDERS.COMPANY')}
        />
      </Form.Layout.Field>
    </BaseUserForm>
  );
};
