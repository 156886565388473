import { observer } from 'mobx-react-lite';
import { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useQuery } from 'react-query';
import { BrowserRouter as Router, Navigate, Route, Routes } from 'react-router-dom';

import { Locale } from '@socialbrothers/constants';
import { Storage } from '@socialbrothers/helpers';
import { AppRoutes, AuthRoutes } from '@socialbrothers/routes';
import { getRoutesFromConfig, isShortLocale } from '@socialbrothers/utils';

import useStores from '@Hooks/useStores';
import { AccountService } from '@Services/AccountService';
import { AppService } from '@Services/AppService';
import { isAdmin, isDealer } from '@Utils/RouteUtils';

import { getAdminRoutes, getDealerRoutes } from './routes';

const PublicRoutes = () => {
  return (
    <Routes>
      {getRoutesFromConfig(AuthRoutes)}

      <Route path="*" element={<Navigate replace to="/" />} />
    </Routes>
  );
};

const PrivateRoutes = () => {
  const { data: userMe } = useQuery(['users', 'me'], AccountService.getMe);
  const { i18n } = useTranslation();

  useEffect(() => {
    const newLang = userMe?.language ?? Locale.NL;

    // @ts-ignore
    i18n.changeLanguage(isShortLocale(newLang) ? Locale[newLang] : newLang);
    Storage.setLocale(newLang as Locale);
    AppService.setLocale(newLang as Locale);
  }, [i18n, userMe?.language]);

  const routes = isAdmin() ? getAdminRoutes() : isDealer() ? getDealerRoutes() : AuthRoutes;
  const allRoutes = [...AppRoutes, ...routes];

  return (
    <Routes>
      {getRoutesFromConfig(allRoutes, true)}

      <Route path="*" element={<Navigate replace to="/" />} />
    </Routes>
  );
};

const RootNavigator = () => {
  const { AuthStore } = useStores();

  return <Router>{AuthStore.isAuthenticated ? <PrivateRoutes /> : <PublicRoutes />}</Router>;
};

export default observer(RootNavigator);
