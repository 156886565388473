import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';

import { Form } from '@socialbrothers/components/Containers';
import { Yup } from '@socialbrothers/utils';

import { CompanyService } from '@Services/Company';
import { storageService } from '@Services/StorageService/StorageService';

export const companySchema = Yup.object({
  name: Yup.string().required(),
  postalCode: Yup.string().required(),
  phoneNumber: Yup.string().required(),
  houseNumber: Yup.string().required(),
  street: Yup.string().required(),
  city: Yup.string().required(),
  image: Yup.mixed(),
});

export const CompanyForm = () => {
  const { id } = useParams<{ id: string }>();
  const { t } = useTranslation();

  const preSubmitFormatter = async (values: any) => {
    if (values.image && values.image.file) {
      const response = await storageService.upload(values.image.file);

      if (response && response[0].id) {
        return {
          ...values,
          image: {
            id: response[0].id,
          },
        };
      }
    }

    return values;
  };

  return (
    <Form.Resource
      preSubmitFormatter={async (values) => {
        return preSubmitFormatter(values);
      }}
      validationSchema={companySchema}
      service={CompanyService}
      label={t('COMPANY.SINGLE')}
      id={id}
    >
      <Form.Layout.Field translationKey="COMPANY.LABELS.NAME">
        <Form.Input.Text name="name" placeholder={t('COMPANY.PLACEHOLDERS.NAME')} />
      </Form.Layout.Field>

      <Form.Layout.Field translationKey="COMPANY.LABELS.POSTALCODE">
        <Form.Input.Text name="postalCode" placeholder={t('COMPANY.PLACEHOLDERS.POSTALCODE')} />
      </Form.Layout.Field>

      <Form.Layout.Field translationKey="COMPANY.LABELS.CITY">
        <Form.Input.Text name="city" placeholder={t('COMPANY.PLACEHOLDERS.CITY')} />
      </Form.Layout.Field>

      <Form.Layout.Field translationKey="COMPANY.LABELS.STREET">
        <Form.Input.Text name="street" placeholder={t('COMPANY.PLACEHOLDERS.STREET')} />
      </Form.Layout.Field>

      <Form.Layout.Field translationKey="COMPANY.LABELS.HOUSENUMBER">
        <Form.Input.Text name="houseNumber" placeholder={t('COMPANY.PLACEHOLDERS.HOUSENUMBER')} />
      </Form.Layout.Field>

      <Form.Layout.Field translationKey="COMPANY.LABELS.PHONENUMBER">
        <Form.Input.Text name="phoneNumber" placeholder={t('COMPANY.PLACEHOLDERS.PHONENUMBER')} />
      </Form.Layout.Field>

      <Form.Layout.Field translationKey="COMPANY.LABELS.IMAGE">
        <Form.Input.File name="image" />
      </Form.Layout.Field>
    </Form.Resource>
  );
};
