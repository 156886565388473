import { useTranslation } from 'react-i18next';

import { Page } from '@socialbrothers/components/Layout';
import { Card } from '@socialbrothers/components/UI';

import { ProjectTable } from '@Components/tables';

export const ProjectOverviewPage = () => {
  const { t } = useTranslation();

  return (
    <Page title={t('PROJECT.PLURAL')}>
      <Card title={t('PROJECT.PLURAL')}>
        <ProjectTable />
      </Card>
    </Page>
  );
};
