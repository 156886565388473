import { get } from 'lodash';

import { Chip } from '@socialbrothers/components/UI';

import styles from './ChipField.module.scss';
import { ChipFieldProps } from './ChipField.props';

const ChipField = ({ source, record, className, render, ...props }: ChipFieldProps) => {
  const value = get(record, source);
  const chips = render(value);

  return (
    <div className={className} {...props}>
      <div className={styles.Chips}>
        {chips && chips.map((chip: string) => <Chip title={chip} />)}
        {!chips && <div>-</div>}
      </div>
    </div>
  );
};

ChipField.displayName = 'ChipField';

export default ChipField;
