import cn from 'classnames';
import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';

import { Button, Icon } from '@socialbrothers/components/UI';

import styles from './Pagination.module.scss';
import { PaginationProps } from './Pagination.props';

const Pagination = ({ perPage = 25, count, onChange, className, ...props }: PaginationProps) => {
  const { t } = useTranslation();
  const [page, setPage] = useState(1);
  const getPages = Math.ceil(count / perPage) || 1;

  React.useEffect(() => {
    onChange(page);
  }, [page, onChange]);

  React.useEffect(() => {
    setPage(1);
  }, [count, perPage]);

  const onPrevious = () => (page > 1 ? setPage((newPage) => newPage - 1) : null);
  const onNext = () => (page < getPages ? setPage((newPage) => newPage + 1) : null);

  return (
    <div className={cn([styles.Pagination, className])} {...props}>
      <Button onClick={onPrevious}>
        <Icon icon="chevron-left" />
      </Button>

      <div className={styles.Page}>
        <div>{t('COMPONENTS.PAGINATION.PAGE')}</div>

        <select onChange={(event) => setPage(parseInt(event.target.value))} value={page}>
          {[...Array(getPages)].map((x, i) => (
            <option key={i} value={i + 1}>
              {i + 1}
            </option>
          ))}
        </select>

        <div>{t('COMPONENTS.PAGINATION.OF_PAGE', { max: getPages })}</div>
      </div>

      <Button onClick={onNext}>
        <Icon icon="chevron-right" />
      </Button>
    </div>
  );
};

export default Pagination;
