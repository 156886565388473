import { useTranslation } from 'react-i18next';

import { Table } from '@socialbrothers/components/Containers';

import { BuildingTypeService, IBuildingType } from '@Services/BuildingType';

import { BuildingTypeTableProps } from './BuildingTypeTable.props';

export const BuildingTypeTable = ({ className }: BuildingTypeTableProps) => {
  const { t } = useTranslation();

  return (
    <Table.Resource<IBuildingType> service={BuildingTypeService} searchable className={className}>
      <Table.Field.Text sortable source="name" label={t('BUILDINGTYPE.LABELS.NAME')} />

      <Table.Field.Date source="createdAt" sortable label={t('GLOBAL.LABELS.CREATEDAT')} />

      <Table.Field.ResourceAction
        editConfig={(record: IBuildingType) => ({
          link: `/buildings/${record.id}`,
        })}
      />
    </Table.Resource>
  );
};
