import { useQuery } from 'react-query';

import { BaseModel, RequestConfig } from '@socialbrothers/constants';
import { ResourceInterface } from '@socialbrothers/services';

export function useList<T extends BaseModel>(
  service: ResourceInterface<T>,
  filters?: RequestConfig<T>,
  enabled = true,
) {
  return useQuery([service.endpoint, JSON.stringify(filters)], () => {
    if (enabled) {
      return service.getList(filters as any);
    }
  });
}
