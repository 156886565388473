import { useFormContext } from 'react-hook-form';

import { HiddenInputProps } from './HiddenInput.props';

const HiddenInput = ({ ...props }: HiddenInputProps) => {
  const { register } = useFormContext();

  return <input {...(props.name ? register(props.name) : {})} type="hidden" />;
};

export default HiddenInput;
