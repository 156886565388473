import {
  Api,
  LoginConfig,
  LoginResponse,
  ResendConfig,
  ResendResponse,
} from '@socialbrothers/constants';
import { Storage } from '@socialbrothers/helpers';
import { BaseService } from '@socialbrothers/services';

import { AppService } from '@Services/index';

class AuthServiceImplementation {
  service: BaseService;

  constructor(service: BaseService) {
    this.service = service;
  }

  login = async (credentials: LoginConfig): Promise<LoginResponse> => {
    const { data } = await this.service.post<LoginResponse>('/auth/login', credentials);

    Storage.setAccessToken(Api.MAIN, data);
    this.service.setAccessToken(data);

    return data;
  };

  resend = async (values: ResendConfig): Promise<ResendResponse> => {
    const { data } = await this.service.post<ResendResponse>('/auth/resend', values);
    return data;
  };

  logout = async () => {
    Storage.removeAccessToken(Api.MAIN);
    this.service.removeAccessToken();

    return true;
  };

  resetPassword = async (
    email: string,
    token: string,
    newPassword: string,
    newPasswordConfirmation: string,
  ) => {
    const { data } = await this.service.post('/auth/reset', {
      email,
      token,
      password: newPassword,
      passwordConfirmation: newPasswordConfirmation,
    });

    return data;
  };

  forgotPassword = async (email: string) => {
    const { data } = await this.service.post('/auth/forgot', {
      email,
    });

    return data;
  };

  resendConfirmation = async (email: string) => {
    const { data } = await this.service.post('/users/register/resend', {
      email,
    });

    return data;
  };
}

export const AuthService = new AuthServiceImplementation(AppService);
