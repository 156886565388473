import { useEffect } from 'react';

import { useStores } from '@Hooks/index';
import { getIsMobile } from '@Utils/ClientUtils';

function useMobile() {
  const { ThemeStore } = useStores();

  const isWindowClient = typeof window === 'object';

  useEffect(() => {
    function setSize() {
      ThemeStore.setIsMobile(getIsMobile());
    }

    if (isWindowClient) {
      window.addEventListener('load', setSize);

      return () => window.removeEventListener('load', setSize);
    }
  }, [isWindowClient, ThemeStore]);

  return false;
}

export default useMobile;
