import { useTranslation } from 'react-i18next';

import { Page } from '@socialbrothers/components/Layout';
import { Card } from '@socialbrothers/components/UI';

import { BuildingTypeTable } from '@Components/tables/BuildingTypeTable/BuildingTypeTable';

export const BuildingTypeOverviewPage = () => {
  const { t } = useTranslation();

  return (
    <Page title={t('BUILDINGTYPE.PLURAL')}>
      <Card title={t('BUILDINGTYPE.PLURAL')}>
        <BuildingTypeTable />
      </Card>
    </Page>
  );
};
