import { useState } from 'react';
import { useTranslation } from 'react-i18next';

import { Button } from '@socialbrothers/components/UI';
import { useModal } from '@socialbrothers/hooks';

import { IBlog } from '@Services/Blog';

import { BlogModal } from '../BlogModal/BlogModal';
import { BuildingTypeBlogTable } from '../BuildingTypeBlogForm/BuildingTypeBlogForm';
import styles from './BlogSection.module.scss';
import type { BlogSectionProps } from './BlogSection.props';

export const BlogSection = ({ blogs, buildingTypeId }: BlogSectionProps) => {
  const { t } = useTranslation();

  const [chosenBlog, setChosenBlog] = useState<IBlog | null>();

  const { onOpen, onClose, isVisible } = useModal();

  const handleOpen = (e: any) => {
    e.stopPropagation();
    setChosenBlog(null);
    onOpen();
  };

  const handleOpenFromTable = (id: string) => {
    setChosenBlog(blogs.find((blog: IBlog) => blog.id === id));
    onOpen();
  };

  return (
    <>
      <br />

      <div className={styles.ButtonWrapper}>
        <Button type="button" onClick={handleOpen} label={t('BLOG.LABELS.ADD_BLOG')} />
      </div>

      <BlogModal
        onClose={onClose}
        buildingTypeId={buildingTypeId}
        isVisible={isVisible}
        blog={chosenBlog}
      />

      <BuildingTypeBlogTable
        buildingTypeId={buildingTypeId}
        blogs={blogs}
        onEdit={handleOpenFromTable}
      />
    </>
  );
};
