import type { ReactElement } from 'react';
import { Navigate, Route } from 'react-router-dom';

import { Routes, RoutesLink } from '@socialbrothers/constants/interfaces';

import useStores from '@Hooks/useStores';

const AuthChecker = ({
  children,
  redirectTo,
  needsAuth,
}: {
  children: ReactElement;
  redirectTo: string;
  needsAuth?: boolean;
}) => {
  const { AuthStore } = useStores();

  const needsRedirect =
    (AuthStore.isAuthenticated && needsAuth) || (!AuthStore.isAuthenticated && !needsAuth);

  return needsRedirect ? children : <Navigate to={redirectTo} />;
};

export const getRoutesFromConfig = (routes: Routes, needsAuth?: boolean) => {
  return routes.map((route: RoutesLink) => {
    if (!route.hidden || (route.hidden() === false && route.element)) {
      return (
        <Route
          key={route.path}
          path={`${route.path}${route.exact ? '' : '/*'}`}
          element={
            <AuthChecker redirectTo="/" needsAuth={needsAuth}>
              {route.element}
            </AuthChecker>
          }
        />
      );
    }

    return null;
  });
};

export const getRouteByKey = (routes: Routes, key: string): RoutesLink => {
  const found = routes.find((route: RoutesLink) => route.key === key) as RoutesLink;

  if (found) return found;

  return {
    key: 'Unknown',
    label: 'Unknown',
    path: '/',
    element: <></>,
  } as RoutesLink;
};

export const isCreate = (id?: any) => {
  return id ? id.length !== 36 : true;
};

export const getMainRoute = (route: string, length: number) => {
  const names = route.split('/');
  return names.slice(1, length).join('/');
};
