import styles from './ReadOnlyField.module.scss';
import { ReadOnlyFieldProps } from './ReadOnlyField.props';

const ReadOnlyField = ({
  children,
  translationKey,
  label,
  description,
  ...props
}: ReadOnlyFieldProps) => {
  return (
    <div className={styles.ReadOnlyField} {...props}>
      <fieldset className={styles.ReadOnlyField__Fieldset}>
        {!!label && <legend className={styles.ReadOnlyField__Label}>{label}</legend>}

        <div className={styles.ReadOnlyField__Children}>{children}</div>
      </fieldset>
    </div>
  );
};

export default ReadOnlyField;
