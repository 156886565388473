import { get } from 'lodash';

import { UrlFieldProps } from './UrlField.props';

const UrlField = ({ source, record, className, ...props }: UrlFieldProps) => {
  const value = get(record, source);

  return (
    <div className={className} {...props}>
      <a className="link" target="_blank" href={value} rel="noopener noreferrer">
        {value}
      </a>
    </div>
  );
};

UrlField.displayName = 'UrlField';

export default UrlField;
