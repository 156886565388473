import i18n from 'i18next';
import LanguageDetector from 'i18next-browser-languagedetector';
import merge from 'lodash/merge';
import { initReactI18next } from 'react-i18next';

import socialbrothers from '@socialbrothers/locales';

import data from './locales';

i18n
  .use(initReactI18next)
  .use(LanguageDetector)
  .init({
    resources: merge(socialbrothers, data),
    // TODO not sure about this query
    detection: {
      order: ['navigator'],
    },
    react: {
      bindI18n: 'languageChanged loaded',
      bindStore: 'added removed',
      nsMode: 'default',
      useSuspense: true,
    },
  });

export default i18n;
