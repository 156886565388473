import { useTranslation } from 'react-i18next';

import { ReadOnlyField } from '@socialbrothers/components/UI';

import type { ProjectReadOnlyFieldProps } from './ProjectReadOnlyField.props';

export const ProjectReadOnlyField = ({
  field,
  text,
  translationKey,
}: ProjectReadOnlyFieldProps) => {
  const { t } = useTranslation();

  return (
    <ReadOnlyField label={t(`PROJECT.LABELS.${translationKey}`)}>
      <p>{field ? text : <i>{t(`PROJECT.PLACEHOLDERS.${translationKey}`)}</i>}</p>
    </ReadOnlyField>
  );
};
