import { useTranslation } from 'react-i18next';

import { ResetPasswordForm } from '@socialbrothers/components/Forms';
import { AuthWrapper } from '@socialbrothers/components/Layout';

import config from '@Config';

const ForgotPasswordPage = () => {
  const { t } = useTranslation();

  const initialValues = {
    password: '',
    passwordConfirmation: '',
  };

  return (
    <AuthWrapper
      imageUri={config.forgotPasswordImage}
      title={t('AUTHENTICATION.RESET_PASSWORD_PAGE.TITLE')}
    >
      <ResetPasswordForm initialValues={initialValues} />
    </AuthWrapper>
  );
};

export default ForgotPasswordPage;
