import { yupResolver } from '@hookform/resolvers/yup';
import { forwardRef, useEffect, useImperativeHandle, useRef } from 'react';
import { FormProvider, useForm } from 'react-hook-form';

import { Button } from '@socialbrothers/components/UI';

import styles from './BaseForm.module.scss';
import { BaseFormProps } from './BaseForm.props';

const Form = (
  {
    validationSchema,
    initialValues,
    onSubmit,
    children,
    error,
    onChange,
    submitLabel,
    disabled,
    ...props
  }: BaseFormProps,
  ref: any,
) => {
  const methods = useForm({
    mode: 'onBlur',
    defaultValues: initialValues,
    reValidateMode: 'onBlur',
    resolver: validationSchema ? yupResolver(validationSchema) : undefined,
  });

  useImperativeHandle(ref, () => methods);

  useEffect(() => {
    if (onChange) {
      const watch = methods.watch((values) => {
        onChange(values);
      });

      return () => {
        if (watch.unsubscribe) {
          watch.unsubscribe();
        }
      };
    }
  }, [methods, onChange]);

  return (
    <FormProvider {...methods}>
      <form onSubmit={onSubmit && methods.handleSubmit(onSubmit)} {...props}>
        {children}

        {error && <div className={styles.BaseForm__Error}>{error}</div>}

        {!!submitLabel && (
          <Button disabled={disabled} type="submit">
            {submitLabel}
          </Button>
        )}
      </form>
    </FormProvider>
  );
};

export default forwardRef(Form);
