import BaseActionField from './BaseActionField/BaseActionField';
import BaseTable from './BaseTable/BaseTable';
import BooleanField from './BooleanField/BooleanField';
import ButtonAction from './ButtonAction/ButtonAction';
import ChipField from './ChipField/ChipField';
import DateField from './DateField/DateField';
import EmailField from './EmailField/EmailField';
import EnumField from './EnumField/EnumField';
import FieldAction from './FieldAction/FieldAction';
import FunctionField from './FunctionField/FunctionField';
import NumberField from './NumberField/NumberField';
import PriceField from './PriceField/PriceField';
import RelationField from './RelationField/RelationField';
import ResourceActionField from './ResourceActionField/ResourceActionField';
import ResourceTable from './ResourceTable/ResourceTable';
import TextField from './TextField/TextField';
import UrlField from './UrlField/UrlField';

const tableCollection = {
  Action: {
    Button: ButtonAction,
    Field: FieldAction,
  },
  Field: {
    Url: UrlField,
    Enum: EnumField,
    Date: DateField,
    Chip: ChipField,
    Text: TextField,
    Email: EmailField,
    Number: NumberField,
    Price: PriceField,
    Boolean: BooleanField,
    Function: FunctionField,
    BaseAction: BaseActionField,
    ResourceAction: ResourceActionField,
    Relation: RelationField,
  },
  Resource: ResourceTable,
  Base: BaseTable,
};

export default tableCollection;
