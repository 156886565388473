import { Button } from '@socialbrothers/components/UI';
import { Color } from '@socialbrothers/constants';

import { ButtonActionProps } from './ButtonAction.props';

const ButtonAction = ({
  record,
  color = Color.PRIMARY,
  icon,
  label,
  className,
  onClick,
  ...props
}: ButtonActionProps) => {
  return (
    <Button color={color} icon={icon} onClick={() => onClick(record)} {...props}>
      {label}
    </Button>
  );
};

export default ButtonAction;
