import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';

import { Form } from '@socialbrothers/components/Containers';
import { Role } from '@socialbrothers/constants';
import { enumToOptions, isCreate as getIsCreate, Yup } from '@socialbrothers/utils';

import { useListInputOptions } from '@Hooks/useListInputOptions';
import { CompanyService } from '@Services/Company';

import { BaseUserForm } from '../../UserForm';

export const validationSchema = Yup.object({
  company: Yup.object({ id: Yup.string().required() }),
});

export const UserAdminForm = () => {
  const { id } = useParams<{ id: string }>();
  const { t } = useTranslation();

  const companyOptions = useListInputOptions(CompanyService);

  const isCreate = getIsCreate(id);

  return (
    <BaseUserForm validationSchema={validationSchema}>
      <Form.Layout.Field translationKey="USER.LABELS.COMPANY">
        <Form.Input.Select
          disabled={!isCreate}
          options={companyOptions}
          name="company.id"
          placeholder={t('USER.PLACEHOLDERS.COMPANY')}
        />
      </Form.Layout.Field>

      <Form.Layout.Field translationKey="USER.LABELS.ROLES">
        <Form.Input.Select
          disabled={!isCreate}
          options={enumToOptions(Role, 'ROLE')}
          name="role"
          placeholder={t('USER.PLACEHOLDERS.ROLES')}
        />
      </Form.Layout.Field>
    </BaseUserForm>
  );
};
