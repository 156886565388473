import React from 'react';
import { createRoot } from 'react-dom/client';
import { QueryClient, QueryClientProvider } from 'react-query';

import './index.css';

import App from './App';
import i18n from './i18n';

// This log is valid for production, since i18n needs to be referenced in order to be executed.
console.log(i18n && 'Initialized i18n');

const queryClient = new QueryClient();
const container = document.getElementById('root');

if (container) {
  const root = createRoot(container);
  root.render(
    <React.StrictMode>
      <QueryClientProvider client={queryClient}>
        <App />
      </QueryClientProvider>
    </React.StrictMode>,
  );
}
