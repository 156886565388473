import { Header, Routes } from '@socialbrothers/constants/interfaces';

import {
  BuildingTypeDetailPage,
  BuildingTypeOverviewPage,
  CompanyDetailPage,
  CompanyOverviewPage,
  CustomerDetailPage,
  CustomerOverviewPage,
  ProjectDetailPage,
  ProjectOverviewPage,
  UserDetailPage,
  UserOverviewPage,
} from '@Pages/index';
import i18n from '@Root/i18n';

export const getAdminRoutes = (): Routes => [
  {
    key: 'CustomerDetailPage',
    label: i18n.t('CUSTOMER.SINGLE'),
    icon: 'user-friends',

    path: '/customers/:id',
    element: <CustomerDetailPage />,
    exact: true,
  },
  {
    key: 'CustomerOverviewPage',
    label: i18n.t('CUSTOMER.PLURAL'),
    icon: 'user-friends',

    path: '/customers',
    element: <CustomerOverviewPage />,
    exact: true,
  },
  {
    key: 'CompanyDetailPage',
    label: i18n.t('COMPANY.SINGLE'),
    icon: 'building',

    path: '/companies/:id',
    element: <CompanyDetailPage />,
    exact: true,
  },
  {
    key: 'CompanyOverviewPage',
    label: i18n.t('COMPANY.PLURAL'),
    icon: 'building',

    path: '/companies',
    element: <CompanyOverviewPage />,
    exact: true,
  },
  {
    key: 'UserDetailPage',
    label: i18n.t('USER.SINGLE'),
    icon: 'user',

    path: '/users/:id',
    element: <UserDetailPage />,
    exact: true,
  },
  {
    key: 'UserOverviewPage',
    label: i18n.t('USER.PLURAL'),
    icon: 'user',

    path: '/users',
    element: <UserOverviewPage />,
    exact: true,
  },
  {
    key: 'CustomerDetailPage',
    label: i18n.t('CUSTOMER.SINGLE'),
    icon: 'user-chart',

    path: '/customers/:id',
    element: <CustomerDetailPage />,
    exact: true,
  },
  {
    key: 'CustomerOverviewPage',
    label: i18n.t('CUSTOMER.PLURAL'),
    icon: 'user-chart',

    path: '/customers',
    element: <CustomerOverviewPage />,
    exact: true,
  },

  {
    key: 'ProjectDetailPage',
    label: i18n.t('PROJECT.SINGLE'),
    icon: 'cabinet-filing',

    path: '/projects/:id',
    element: <ProjectDetailPage />,
    exact: true,
  },
  {
    key: 'ProjectOverviewPage',
    label: i18n.t('PROJECT.PLURAL'),
    icon: 'cabinet-filing',

    path: '/',
    element: <ProjectOverviewPage />,
    exact: true,
  },
];

export const getDealerRoutes = (): Routes => [
  {
    key: 'CustomerDetailPage',
    label: i18n.t('CUSTOMER.SINGLE'),
    icon: 'user-friends',

    path: '/customers/:id',
    element: <CustomerDetailPage />,
    exact: true,
  },
  {
    key: 'CustomerOverviewPage',
    label: i18n.t('CUSTOMER.PLURAL'),
    icon: 'user-friends',

    path: '/customers',
    element: <CustomerOverviewPage />,
    exact: true,
  },
  {
    key: 'ProjectDetailPage',
    label: i18n.t('PROJECT.SINGLE'),
    icon: 'cabinet-filing',

    path: '/projects/:id',
    element: <ProjectDetailPage />,
    exact: true,
  },
  {
    key: 'ProjectOverviewPage',
    label: i18n.t('PROJECT.PLURAL'),
    icon: 'cabinet-filing',

    path: '/',
    element: <ProjectOverviewPage />,
    exact: true,
  },
  {
    key: 'BuildingTypeDetailPage',
    label: i18n.t('BUILDINGTYPE.SINGLE'),
    icon: 'comment-alt-music',

    path: '/buildings/:id',
    element: <BuildingTypeDetailPage />,
    exact: true,
  },
  {
    key: 'BuildingTypeOverviewPage',
    label: i18n.t('BUILDINGTYPE.PLURAL'),
    icon: 'comment-alt-music',

    path: '/buildings',
    element: <BuildingTypeOverviewPage />,
    exact: true,
  },
  {
    key: 'UserDetailPage',
    label: i18n.t('USER.SINGLE'),
    icon: 'user',

    path: '/users/:id',
    element: <UserDetailPage />,
    exact: true,
  },
  {
    key: 'UserOverviewPage',
    label: i18n.t('USER.PLURAL'),
    icon: 'user',

    path: '/users',
    element: <UserOverviewPage />,
    exact: true,
  },
];

export const header: Header = [
  {
    label: i18n.t('ACCOUNT.TITLE'),
    link: '/account',
  },
];
