import { IUser } from '@Services/User';

export type FormData = RawFormData & RawAcousticFormData;
export type RawFormData = Record<string, unknown>;
export type RawAcousticFormData = Record<string, IAcousticValue[]>;
export type FormDataEntry = RawAcousticFormDataEntry | RawFormDataEntry;
export type RawFormDataEntry = [string, unknown];
export type RawAcousticFormDataEntry = [string, IAcousticValue[]];

export type AVCategories = keyof typeof AcousticValueCategories;
export type AVKeys = keyof typeof AcousticValueKeys;

export interface AcousticFormData {
  [key: string]: any;
  _acousticValues?: {
    [source: string]: ({
      category: AVCategories;
    } & Record<AVKeys, string>)[];
  };
}

export enum AcousticValueKeys {
  HZ_125 = 'HZ_125',
  HZ_250 = 'HZ_250',
  HZ_500 = 'HZ_500',
  HZ_1000 = 'HZ_1000',
  HZ_2000 = 'HZ_2000',
  HZ_4000 = 'HZ_4000',
}

export enum AcousticValueCategories {
  INITIAL_MEASUREMENT = 'INITIAL_MEASUREMENT',
  ACOUSTIC_BASELINE = 'ACOUSTIC_BASELINE',
  ACOUSTIC_ACCEPTABLE = 'ACOUSTIC_ACCEPTABLE',
  ACOUSTIC_IDEAL = 'ACOUSTIC_IDEAL',
  ACOUSTIC_MAXIMAL = 'ACOUSTIC_MAXIMAL',
  DAMPING = 'DAMPING',
  DAMPING_250 = 'DAMPING_250',
  DAMPING_1000 = 'DAMPING_1000',
  ABSORPTION = 'ABSORPTION',
  REVERB = 'REVERB',
  REVERB_ACCEPTABLE = 'REVERB_ACCEPTABLE',
  REVERB_DESIRABLE = 'REVERB_DESIRABLE',
  REVERB_MINIMAL = 'REVERB_MINIMAL',
  NEEDED_FOR_OPTIMAL = 'NEEDED_FOR_OPTIMAL',
}

export interface IAcousticValue {
  id: string;
  key: AVKeys;
  value: number;
  category: AVCategories;
}

export interface IRole {
  concurrencyStamp: string;
  id: string;
  name: string;
  normalizedName: string;
  userRoles: IUserRole[];
  users: IUser[];
}

export interface IUserRole {
  userId: string;
  roleId: string;
  user: IUser;
}

export interface IBaseModel {
  id: string;
}
