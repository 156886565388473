import de from './de';
import en from './en';
import fr from './fr';
import nl from './nl';

const data = {
  de: de,
  en: en,
  fr: fr,
  nl: nl,
};

export default data;
