import { Controller, useFormContext } from 'react-hook-form';
import ReactQuill from 'react-quill';

import styles from './RichTextInput.module.scss';
import RichTextInputProps from './RichTextInput.props';

const RichTextInput = ({ name, onChange, ...props }: RichTextInputProps) => {
  const { control, setValue } = useFormContext();

  const formats = ['bold', 'italic', 'underline', 'strike', 'list', 'bullet', 'link'];
  const modules = {
    toolbar: [
      ['bold', 'italic', 'underline', 'strike'],
      [{ list: 'ordered' }, { list: 'bullet' }],
      ['link'],
    ],
  };

  return (
    <div className={styles.RichText} {...props}>
      <Controller
        name={name}
        control={control}
        render={({ field: { value, name: fieldName } }) => {
          return (
            <ReactQuill
              modules={modules}
              formats={formats}
              value={value || ''}
              onChange={(e) => setValue(fieldName, e.replace('<p><br></p>', ''))}
            />
          );
        }}
      />
    </div>
  );
};

export default RichTextInput;
