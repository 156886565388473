import { Instance, types } from 'mobx-state-tree';

import { AuthStore } from '@socialbrothers/stores';

import ThemeStore from './Theme/ThemeStore';

const RootStoreModel = types
  .model('RootStoreModel', {
    AuthStore,
    ThemeStore,
  })
  .actions(() => ({}));

const RootStore = RootStoreModel.create({
  AuthStore: {},
  ThemeStore: {
    Sidebar: {},
  },
});

export interface RootStoreInstance extends Instance<typeof RootStoreModel> {}
export default RootStore;
