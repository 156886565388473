import cn from 'classnames';
import { useTranslation } from 'react-i18next';

import { Table } from '@socialbrothers/components/Containers';
import { Button } from '@socialbrothers/components/UI';
import { Color } from '@socialbrothers/constants';

import { useListInputOptions } from '@Hooks/index';
import { CustomerService } from '@Services/Customer';
import { IProject, ProjectService } from '@Services/Project';

import { ProjectTableProps } from './ProjectTable.props';

export const ProjectTable = ({ className, defaultFilters }: ProjectTableProps) => {
  const { t } = useTranslation();

  const customers = useListInputOptions(CustomerService, undefined, (record) => record.name);

  return (
    <Table.Resource<IProject>
      service={ProjectService}
      defaultFilters={defaultFilters}
      searchable
      className={cn(className)}
    >
      <Table.Field.Text sortable source="name" label={t('PROJECT.LABELS.NAME')} />

      <Table.Field.Text
        sortable
        filterable
        source="building.name"
        label={t('PROJECT.LABELS.BUILDING')}
      />

      <Table.Field.Relation
        options={customers}
        sortable
        filterable
        source="customer.id"
        namePath="customer.name"
        label={t('PROJECT.LABELS.CUSTOMER')}
      />

      <Table.Field.Function
        render={({ advisor }) => `${advisor.firstName} ${advisor.lastName}`}
        sortable
        filterable
        source="advisor.fullName"
        label={t('PROJECT.LABELS.ADVISOR')}
      />

      <Table.Field.Date sortable source="updatedAt" label={t('GLOBAL.LABELS.UPDATEDAT')} />

      <Table.Field.Date sortable source="createdAt" label={t('GLOBAL.LABELS.CREATEDAT')} />

      <Table.Action.Field
        render={(record) => {
          return (
            <Button icon="chevron-right" to={`/projects/${record.id}`} color={Color.PRIMARY} />
          );
        }}
      />
    </Table.Resource>
  );
};
