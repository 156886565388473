import { useTranslation } from 'react-i18next';
import { useMutation, useQueryClient } from 'react-query';
import { toast } from 'react-toastify';

import { Form } from '@socialbrothers/components/Containers';
import { Button, Modal } from '@socialbrothers/components/UI';
import { Color } from '@socialbrothers/constants';
import { isCreate } from '@socialbrothers/utils';

import { useListInputOptions } from '@Hooks/useListInputOptions';
import { BlogService } from '@Services/Blog';
import { BuildingTypeService } from '@Services/BuildingType';
import { CustomerService } from '@Services/Customer';
import { storageService } from '@Services/StorageService/StorageService';

import styles from './BlogModal.module.scss';
import { BlogModalProps } from './BlogModal.props';

export const BlogModal = ({ buildingTypeId, onClose, isVisible, blog }: BlogModalProps) => {
  const { t } = useTranslation();

  const queryClient = useQueryClient();

  const handleSuccess = () => {
    onClose();
  };

  const mutateCreate = useMutation(BlogService.create, {
    onSuccess: () => {
      queryClient.invalidateQueries(BlogService.endpoint);
      queryClient.invalidateQueries([BuildingTypeService.endpoint, buildingTypeId]);

      toast.success(t('GLOBAL.CREATED_SUCCESSFUL', { name: t('BLOG.SINGLE') }));
      handleSuccess();
    },
    onError: () => {
      toast.error(t('GLOBAL.CREATED_UNSUCCESSFUL', { name: t('BLOG.SINGLE') }));
    },
  });

  const mutateUpdate = useMutation(
    (values) => BlogService.update(blog?.id as string, values as any),
    {
      onSuccess: () => {
        queryClient.invalidateQueries(BlogService.endpoint);
        queryClient.invalidateQueries([BuildingTypeService.endpoint, buildingTypeId]);

        toast.success(t('GLOBAL.UPDATED_SUCCESSFUL', { name: t('BLOG.SINGLE') }));
        handleSuccess();
      },
      onError: () => {
        toast.error(t('GLOBAL.UPDATED_UNSUCCESSFUL', { name: t('BLOG.SINGLE') }));
      },
    },
  );

  const onSubmit = async (values: any) => {
    let params = values;

    if (values.image?.file) {
      const response = await storageService.upload(values.image.file);

      params = { ...values, image: { id: response[0].id } };
    }

    if (isCreate(blog?.id)) {
      return mutateCreate.mutateAsync(params);
    } else {
      return mutateUpdate.mutateAsync(params);
    }
  };

  const customerOptions = useListInputOptions(CustomerService);

  return (
    <Modal title={t('BLOG.LABELS.ADD_BLOG')} onClose={onClose} isVisible={isVisible}>
      <Form.Base
        onSubmit={onSubmit}
        initialValues={{
          ...blog,
          building: {
            ...blog?.building,
            id: buildingTypeId,
          },
        }}
      >
        <Form.Layout.Field translationKey="BLOG.LABELS.NAME">
          <Form.Input.Text name="name" placeholder={t('BLOG.PLACEHOLDERS.NAME')} />
        </Form.Layout.Field>

        <Form.Layout.Field translationKey="BLOG.LABELS.DESCRIPTION">
          <Form.Input.Multiline
            name="description"
            placeholder={t('BLOG.PLACEHOLDERS.DESCRIPTION')}
          />
        </Form.Layout.Field>

        <p className={styles.Hint}>{t('BLOG.HINTS.DESCRIPTION')}</p>

        <Form.Layout.Field>
          <Form.Input.Select
            name="customer.id"
            placeholder={t('BLOG.PLACEHOLDERS.CUSTOMER')}
            options={customerOptions}
          />
        </Form.Layout.Field>

        <Form.Layout.Field translationKey="BLOG.LABELS.IMAGE">
          <Form.Input.File name="image" />
        </Form.Layout.Field>

        <p className={styles.Hint}>{t('BLOG.HINTS.IMAGE')}</p>

        <Form.Input.Hidden name="building.id" value={buildingTypeId} />
        <hr />

        <div className="d-flex justify-content-end">
          <Button type="submit" color={Color.PRIMARY}>
            {isCreate(blog?.id) ? t('BLOG.LABELS.ADD_BLOG') : t('BLOG.LABELS.UPDATE_BLOG')}
          </Button>

          <Button type="button" onClick={onClose} color={Color.GRAY}>
            {t('RESOURCE.DELETE.MODAL.CANCEL')}
          </Button>
        </div>
      </Form.Base>
    </Modal>
  );
};
