import { ToastContainer } from 'react-toastify';

import './App.scss';

import { ThemeProvider, useSentry } from '@socialbrothers/hooks';
import { yupSetup } from '@socialbrothers/utils';

import { LanguageProvider } from '@Components/containers/LanguageSwitcher/LanguageSwitcher';
import config from '@Config';

import { RootNavigator } from './routes';

const App = () => {
  useSentry();
  yupSetup();

  return (
    <ThemeProvider context={config}>
      <LanguageProvider>
        <ToastContainer />

        <RootNavigator />
      </LanguageProvider>
    </ThemeProvider>
  );
};

export default App;
