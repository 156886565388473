import i18n from 'i18next';
import { get } from 'lodash';

import { BaseOptionModel, ExtractFunction, ModelOptions } from '../constants';

export const getFilterableFields = (fields: any[]) => {
  return fields.reduce((filterableFields, field) => {
    if (!field.filterable) {
      return filterableFields;
    }

    return [...filterableFields, { key: field.source, value: field.label }];
  }, []);
};

export const enumToOptions = (enumeration: any, enumName: string) => {
  return Object.values<string>(enumeration).reduce<{ key: string; value: string }[]>(
    (total, item) => {
      return [...total, { key: item, value: i18n.t(`ENUM.${enumName}.${item}`) }];
    },
    [],
  );
};

export const modelToOptions = <T extends BaseOptionModel>(
  models: T[],
  value: string | ExtractFunction<T>,
) => {
  const options = models.map<ModelOptions<T>>((model: T) => {
    const label = typeof value === 'string' ? get(model, value) : value(model);

    return {
      key: model.id,
      value: label,
      disabled: model.enabled === 0,
    };
  });

  return options;
};
