import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Link, useLocation } from 'react-router-dom';
import { useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';

import { Form } from '@socialbrothers/components/Containers';
import { Button } from '@socialbrothers/components/UI';
import { useTheme } from '@socialbrothers/hooks';
import { Yup } from '@socialbrothers/utils';

import config from '@Config';

import styles from './ResetPasswordForm.module.scss';
import Props, { ResetPasswordFormValues } from './ResetPasswordForm.props';

const ResetPasswordForm = ({ initialValues, ...props }: Props) => {
  const { t } = useTranslation();
  const { authService } = useTheme();
  const location = useLocation();
  const [error, setError] = useState('');
  const navigate = useNavigate();

  const validationSchema = Yup.object().shape({
    password: Yup.string().password().matches(config.passwordRegex).required(),
    passwordConfirmation: Yup.string()
      .password('password')
      .matches(config.passwordRegex)
      .required(),
  });

  const params = new URLSearchParams(location.search);
  const token = params.get('token');
  const email = params.get('email');

  useEffect(() => {
    if (!token || !email) {
      navigate('/');
    }
  }, [token, email, navigate]);

  const handleSubmit = async ({ password, passwordConfirmation }: ResetPasswordFormValues) => {
    if (email && token) {
      try {
        await authService.resetPassword(email, token, password, passwordConfirmation);

        toast.success(t('AUTHENTICATION.RESET_PASSWORD_PAGE.RESET_SUCCESSFUL'));
        navigate('/');
      } catch (e) {
        setError(t('VALIDATION.COMMON.UNKNOWN_ERROR'));
      }
    }
  };

  return (
    <div className={styles.ResetPasswordForm}>
      <Form.Base
        onSubmit={handleSubmit}
        validationSchema={validationSchema}
        error={error}
        {...props}
      >
        <Form.Layout.Field translationKey="FORMS.COMMON_LABELS.NEW_PASSWORD">
          <Form.Input.Text type="password" name="password" />
        </Form.Layout.Field>

        <Form.Layout.Field translationKey="FORMS.COMMON_LABELS.NEW_PASSWORD_VERIFY">
          <Form.Input.Text type="password" name="passwordConfirmation" />
        </Form.Layout.Field>

        <Button className={styles.Button} block type="submit">
          {t('AUTHENTICATION.RESET_PASSWORD_PAGE.SUBMIT_LABEL')}
        </Button>
      </Form.Base>

      <div className={styles.Footer}>
        <Link to="">{t('AUTHENTICATION.RESET_PASSWORD_PAGE.BACK')}</Link>
      </div>
    </div>
  );
};

export default ResetPasswordForm;
