import ReactDOM from 'react-dom';

import { useEscape } from '@socialbrothers/hooks/index';

import styles from './Modal.module.scss';
import { ModalProps } from './Modal.props';

const Modal = ({ title, isVisible, onClose, children, ...props }: ModalProps) => {
  useEscape(onClose && isVisible ? onClose : () => undefined);

  if (isVisible) {
    return ReactDOM.createPortal(
      <div {...props}>
        <div className={styles.Overlay} />

        <div className={styles.Wrapper}>
          <div className={styles.Modal}>
            <div className={styles.Header}>
              <h3 className="mb-0">{title}</h3>

              {onClose && (
                <div className={styles.Close} onClick={onClose}>
                  <i className="far fa-times" />
                </div>
              )}
            </div>

            <div className={styles.Body}>{children}</div>
          </div>
        </div>
      </div>,
      document.body,
    );
  }

  return <></>;
};

export default Modal;
