import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';

import { Page } from '@socialbrothers/components/Layout';
import { Card } from '@socialbrothers/components/UI';
import { useResource } from '@socialbrothers/hooks';

import { CustomerForm } from '@Components/forms';
import { CustomerService, ICustomer } from '@Services/Customer';

export const CustomerDetailPage = () => {
  const { t } = useTranslation();
  const { id } = useParams<{ id: string }>();
  const { data } = useResource<ICustomer>(CustomerService, id);

  const label = data?.name ? data.name : t('CUSTOMER.SINGLE');

  return (
    <Page title={label}>
      <Card title={label}>
        <CustomerForm />
      </Card>
    </Page>
  );
};
