import { Account, AccountServiceInterface } from '@socialbrothers/constants';
import { BaseService } from '@socialbrothers/services';

import { AppService } from '@Services/index';

import { IUser } from './User';

class AccountServiceImplementation implements AccountServiceInterface {
  client: BaseService;

  endpoint: string;

  constructor(client: BaseService, endpoint: string) {
    this.endpoint = endpoint;
    this.client = client;
  }

  getMe = async () => {
    const result = await this.client.get<IUser>('/me');

    return result.data;
  };

  updateMe = async (data: Account) => {
    const result = await this.client.put<Account>(`${this.endpoint}/${data.id}`, data);

    return result.data;
  };
}

export const AccountService = new AccountServiceImplementation(AppService, '/admin/users');
