import { useFormContext } from 'react-hook-form';

import { TextInputProps } from './TextInput.props';

const TextInput = ({ ...props }: TextInputProps) => {
  const { register } = useFormContext();

  return (
    <input {...props} {...(props.name ? register(props.name) : {})} type={props.type ?? 'text'} />
  );
};

export default TextInput;
