import { useTranslation } from 'react-i18next';

import { Table } from '@socialbrothers/components/Containers';
import { Operator, Role } from '@socialbrothers/constants';

import { useListInputOptions } from '@Hooks/useListInputOptions';
import { CustomerService, ICustomer } from '@Services/Customer';
import { UserService } from '@Services/User';

import { CustomerTableProps } from './CustomerTable.props';

export const CustomerTable = ({ defaultFilters, className }: CustomerTableProps) => {
  const { t } = useTranslation();

  const accountManagers = useListInputOptions(UserService, {
    [`roles.name`]: [
      {
        operator: Operator.IS,
        value: Role.ADVISOR,
      },
    ],
  });

  return (
    <Table.Resource<ICustomer>
      service={CustomerService}
      defaultFilters={defaultFilters}
      className={className}
    >
      <Table.Field.Text source="name" label={t('CUSTOMER.LABELS.NAME')} sortable filterable />

      <Table.Field.Text source="number" label={t('CUSTOMER.LABELS.NUMBER')} sortable filterable />

      <Table.Field.Relation
        options={accountManagers}
        source="accountManager.firstName"
        renderName={(record: ICustomer) =>
          `${record.accountManager.firstName} ${record.accountManager.lastName}`
        }
        label={t('CUSTOMER.LABELS.ACCOUNTMANAGER')}
        sortable
        filterable
      />

      <Table.Field.Text source="email" label={t('CUSTOMER.LABELS.EMAIL')} sortable filterable />

      <Table.Field.Text source="phone" label={t('CUSTOMER.LABELS.PHONE')} sortable filterable />

      <Table.Field.ResourceAction
        deleteConfig={(record: ICustomer) => ({
          name: record.name,
        })}
        editConfig={(record: ICustomer) => ({
          link: `/customers/${record.id}`,
        })}
      />
    </Table.Resource>
  );
};
