import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';

import { Page } from '@socialbrothers/components/Layout';
import { Card } from '@socialbrothers/components/UI';
import { useResource } from '@socialbrothers/hooks';

import { CompanyForm } from '@Components/forms';
import { CompanyService, ICompany } from '@Services/Company';

export const CompanyDetailPage = () => {
  const { t } = useTranslation();
  const { id } = useParams<{ id: string }>();
  const { data } = useResource<ICompany>(CompanyService, id);

  const label = data?.name ? data.name : t('COMPANY.SINGLE');

  return (
    <Page title={label}>
      <Card title={label}>
        <CompanyForm />
      </Card>
    </Page>
  );
};
