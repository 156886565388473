import { FilterFormEntry } from '../components/Containers/Table/Filter/Filter.props';
import { Filter } from '../constants';

export const formatFilterToFormValues = (filter: Filter<any>) => {
  return Object.entries(filter).reduce<FilterFormEntry[]>((total, [key, array]) => {
    if (array) {
      array.forEach(({ operator, value }) => {
        total.push({
          field: key,
          operator: operator,
          value: value,
        });
      });
    }

    return total;
  }, []);
};
