import { ResourceService } from '@socialbrothers/services';

import { AppService } from '@Services/index';
import { IUser } from '@Services/User';

export class UserServiceImplementation extends ResourceService<IUser> {
  getMe = async () => {
    const response = await this.client.get<IUser>(`/me`);

    return response.data;
  };
}

export const UserService = new UserServiceImplementation(AppService, '/admin/users');
