import cn from 'classnames';
import { forwardRef } from 'react';
import { useFormContext } from 'react-hook-form';

import styles from './CheckboxInput.module.scss';
import { CheckboxInputProps } from './CheckboxInput.props';

const CheckboxInput = ({ options, inline = false, name, ...props }: CheckboxInputProps) => {
  const { register } = useFormContext();

  return (
    <div className={cn(styles.CheckboxInput, { [styles.Inline]: inline })} {...props}>
      {options.map(({ key, value }) => {
        return (
          <div className={styles.Option} key={key}>
            <input type="checkbox" id={String(`${name}-${key}`)} value={key} {...register(name)} />
            <label htmlFor={String(`${name}-${key}`)}>{value}</label>
          </div>
        );
      })}
    </div>
  );
};

export default forwardRef(CheckboxInput);
