import Background from '@Assets/images/background.jpg';

import { AccountService, AuthService } from './services';

const config = {
  loginImage: Background,
  forgotPasswordImage: Background,
  passwordRegex:
    /(?=^.{8,24}$)(?=.*\d)(?=.*[a-z])(?=.*[A-Z])(?=.*[!@#$%^&*()_\-+}{&;:;'?/&.,<>])(?!.*\s).*$/g, // One lowercase, one uppercase, one digit, one special char, length 8 - 24
  authService: AuthService,
  accountService: AccountService,
};

export default config;

export const ERROR_EMAIL_NOT_CONFIRMED = 'EmailNotConfirmed';
