import { YupFileExtensionStatus } from '../constants';

export const getFileURL = (filename: string) => {
  return `${process.env.REACT_APP_API_URL}/${filename}`;
};

export const isFileImage = (
  path: string,
): Promise<{ path: string; status: YupFileExtensionStatus }> => {
  return new Promise((resolve) => {
    const img = new Image();
    img.onload = () => resolve({ path, status: YupFileExtensionStatus.OK });
    img.onerror = () => resolve({ path, status: YupFileExtensionStatus.ERROR });

    img.src = path;
  });
};

export const isFile = (input: unknown): input is File => 'File' in window && input instanceof File;

export const downloadFile = (data: any, fileName: string) => {
  var blob = new Blob([data], { type: 'application/octetstream' });
  const doc: any = document;

  //Check the Browser type and download the File.
  var isIE = false || !!doc.documentMode;
  if (isIE) {
    // @ts-ignore
    window.navigator.msSaveBlob(blob, fileName);
  } else {
    var url = window.URL || window.webkitURL;
    const link = url.createObjectURL(blob);
    var a = document.createElement('a');
    a.setAttribute('download', fileName);
    a.setAttribute('href', link);
    document.body.appendChild(a);
    a.click();
    document.body.removeChild(a);
  }
};

export const downloadFromUrl = (url: string, fileName: string) => {
  //Create XMLHTTP Request.
  var req = new XMLHttpRequest();
  req.open('GET', url, true);
  req.responseType = 'blob';
  req.onload = function () {
    //Convert the Byte Data to BLOB object.
    downloadFile(req.response, fileName);
  };
  req.send();
};
