import { Instance } from 'mobx-state-tree';
import { createContext, useContext } from 'react';

import { TableStoreInstance } from '@socialbrothers/stores/Table/TableStore';

export interface TableInstance {
  fields: any[];
  data: any[];
  filter: TableStoreInstance;
  service?: any;
  options?: any;
}

export const TableContext = createContext<null | TableInstance>(null);
export const TableProvider = TableContext.Provider;

export function useTable(): Instance<TableInstance> {
  const context = useContext(TableContext);

  if (context === null) {
    throw new Error('TableContext cannot be null, please add a context provider');
  }

  return context;
}
