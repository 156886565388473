import { get } from 'lodash';
import {
  ForwardedRef,
  forwardRef,
  useCallback,
  useEffect,
  useImperativeHandle,
  useRef,
} from 'react';
import { UseFormReturn } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { useMutation, useQueryClient } from 'react-query';
import { useLocation, useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';

import { Form, Resource } from '@socialbrothers/components/Containers';
import { Spinner } from '@socialbrothers/components/UI';
import { useResource } from '@socialbrothers/hooks';
import { getMainRoute, isCreate } from '@socialbrothers/utils';

import { useLanguage } from '@Components/containers/LanguageSwitcher/LanguageSwitcher';

import styles from './ResourceForm.module.scss';
import { ResourceFormProps } from './ResourceForm.props';

const ResourceForm = (
  {
    id,
    service,
    submitLabel,
    deleteConfig,
    children,
    validationSchema,
    label,
    shouldNavigate = true,
    hasSuccessData = true,
    preSubmitFormatter,
    afterFetchFormatter,
    onDataFetched,
    onError,
    onSuccess,
    onChange,
  }: ResourceFormProps,
  ref: ForwardedRef<any>,
) => {
  const queryClient = useQueryClient();
  const query = useResource(service, id);

  const { t } = useTranslation();
  const navigate = useNavigate();
  const location = useLocation();
  const { locale } = useLanguage();
  const formRef = useRef<UseFormReturn>();

  useImperativeHandle(ref, () => formRef.current);

  useEffect(() => {
    (async () => {
      const response = await query.refetch();

      if (formRef.current) {
        formRef.current.reset(response.data);
      }
    })();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [locale]);

  const getData = useCallback(() => {
    if (afterFetchFormatter) {
      return afterFetchFormatter(query.data);
    }

    return query.data;
  }, [afterFetchFormatter, query.data]);

  useEffect(() => {
    if (query.data && onDataFetched) {
      onDataFetched(query.data);
    }
  }, [onDataFetched, query.data]);

  const mutateCreate = useMutation(service.create, {
    onSuccess: (response: any) => {
      queryClient.invalidateQueries(service.endpoint);

      if (response.id && shouldNavigate) {
        const currentPath = getMainRoute(location.pathname, -1);
        navigate(`/${currentPath}/${response.id}`);
      }

      toast.success(t('GLOBAL.CREATED_SUCCESSFUL', { name: label }));

      if (hasSuccessData) {
        onSuccess && onSuccess(response.response.data);
      } else {
        onSuccess && onSuccess(undefined);
      }
    },
    onError: (response: any) => {
      toast.error(t('GLOBAL.CREATED_UNSUCCESSFUL', { name: label }));

      onError && onError(response.response.data);
    },
  });

  const mutateUpdate = useMutation((values) => service.update(id as string, values), {
    onSuccess: (response: any) => {
      toast.success(t('GLOBAL.UPDATED_SUCCESSFUL', { name: label }));
      queryClient.invalidateQueries(service.endpoint);

      if (hasSuccessData) {
        onSuccess && onSuccess(response.response.data);
      } else {
        onSuccess && onSuccess(undefined);
      }
    },
    onError: (response: any) => {
      toast.error(t('GLOBAL.UPDATED_UNSUCCESSFUL', { name: label }));

      onError && onError(response.response.data);
    },
  });

  const defaultSubmitLabel = isCreate(id) ? t('GLOBAL.CREATE') : t('GLOBAL.UPDATE');

  const handleSubmit = async (values: any) => {
    const params = preSubmitFormatter ? await preSubmitFormatter(values, !!id) : values;

    if (isCreate(id)) {
      return mutateCreate.mutateAsync(params);
    } else {
      return mutateUpdate.mutateAsync(params);
    }
  };

  return (
    <>
      {!query.isFetched && !isCreate(id) ? (
        <Spinner size={40} />
      ) : (
        <Form.Base
          ref={formRef}
          submitLabel={submitLabel || defaultSubmitLabel}
          initialValues={getData()}
          validationSchema={validationSchema}
          onSubmit={handleSubmit}
          onChange={onChange}
        >
          {children}

          {!isCreate(id) && id && deleteConfig && !deleteConfig.disabled && (
            <div className={styles.Delete}>
              <Resource.Delete
                id={id}
                hasLabel
                service={service}
                redirect={deleteConfig.redirect}
                name={get(getData(), deleteConfig.name)}
              />
            </div>
          )}
        </Form.Base>
      )}
    </>
  );
};

export default forwardRef(ResourceForm);
