export const getInputNameFromElement = (element: JSX.Element): string => {
  if (!element.props.children && element.props.name) {
    return element.props.name;
  }

  if (element.props.children) {
    return getInputNameFromElement(element.props.children);
  }

  return '';
};

export const getInputNameFromChildren = (children: JSX.Element | JSX.Element[]): string => {
  if (!children) {
    return '';
  }

  if (Array.isArray(children)) {
    for (const child of children) {
      const name = getInputNameFromElement(child);

      if (name) {
        return name;
      }
    }

    return '';
  } else {
    return getInputNameFromElement(children);
  }
};
