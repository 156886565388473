import { get } from 'lodash';

import { formatPrice } from '@socialbrothers/utils/StringUtils';

import { PriceFieldProps } from './PriceField.props';

const PriceField = ({ source, record, withtotals, className, ...props }: PriceFieldProps) => {
  const value = get(record, source);

  const getTotal = () => {
    if (typeof withtotals === 'string') {
      return parseFloat(get(record, withtotals)) * parseFloat(value);
    }

    return value;
  };

  return (
    <div className={className} {...props}>
      {formatPrice(getTotal())}
    </div>
  );
};

PriceField.displayName = 'PriceField';

export default PriceField;
