import { ResourceService } from '@socialbrothers/services';

import { AppService } from '..';
import type { IStorage } from './StorageService.props';

export class StorageService extends ResourceService<IStorage> {
  upload = async (files: File | File[]) => {
    const data = new FormData();

    if (Array.isArray(files)) {
      files.map((file) => data.append('files', file));
    } else {
      data.append('files', files);
    }

    const response = await this.client.post<IStorage[]>('admin/storage', data, {
      headers: { 'Content-Type': 'multipart/form-data' },
    });

    return response.data;
  };
}

export const storageService = new StorageService(AppService, '/admin/storage');
