import { useTranslation } from 'react-i18next';

import { Table } from '@socialbrothers/components/Containers';

import { useAdmin } from '@Hooks/useAdmin';
import { IUser, UserService } from '@Services/User';

import { UserTableProps } from './UserTable.props';

export const UserTable = ({ defaultFilters, className }: UserTableProps) => {
  const { isAdmin } = useAdmin();

  const { t } = useTranslation();

  return (
    <Table.Resource<IUser>
      service={UserService}
      className={className}
      defaultFilters={defaultFilters}
    >
      {isAdmin
        ? [
            <Table.Field.Text source="firstName" label={t('USER.LABELS.FIRSTNAME')} />,
            <Table.Field.Text source="lastName" label={t('USER.LABELS.LASTNAME')} />,
            <Table.Field.Text source="company.name" label={t('USER.LABELS.COMPANY')} />,
            <Table.Field.Text source="email" label={t('USER.LABELS.EMAIL')} />,
            <Table.Field.Date sortable source="createdAt" label={t('GLOBAL.LABELS.CREATEDAT')} />,
            <Table.Field.ResourceAction
              deleteConfig={(record: IUser) => ({
                name: record.id,
              })}
              editConfig={(record: IUser) => ({
                link: `/users/${record.id}`,
              })}
            />,
          ]
        : [
            <Table.Field.Text source="firstName" label={t('USER.LABELS.FIRSTNAME')} />,
            <Table.Field.Text source="lastName" label={t('USER.LABELS.LASTNAME')} />,
            <Table.Field.Text source="email" label={t('USER.LABELS.EMAIL')} />,
            <Table.Field.Date sortable source="createdAt" label={t('GLOBAL.LABELS.CREATEDAT')} />,
            <Table.Field.ResourceAction
              deleteConfig={(record: IUser) => ({
                name: `${record.firstName} ${record.lastName}`,
              })}
              editConfig={(record: IUser) => ({
                link: `/users/${record.id}`,
              })}
            />,
          ]}
    </Table.Resource>
  );
};
