import i18next from 'i18next';

import type { Routes } from '@socialbrothers/constants';
import { ForgotPasswordPage, LoginPage, ResetPasswordPage } from '@socialbrothers/pages';

const routes: Routes = [
  {
    key: 'LoginPage',
    label: 'Login',
    icon: 'user',

    path: '/',
    element: <LoginPage />,
    exact: true,
  },
  {
    key: 'ForgotPasswordPage',
    label: i18next.t('FORGOT_PASSWORD.LABEL'),
    icon: 'user',

    path: '/forgot-password',
    element: <ForgotPasswordPage />,
    exact: true,
  },
  {
    key: 'ResetPasswordPage',
    label: i18next.t('RESET_PASSWORD.LABEL'),
    icon: 'user',
    path: '/reset-password',
    element: <ResetPasswordPage />,
    exact: true,
  },
];
export default routes;
