import { useQuery } from 'react-query';

import { Role } from '@socialbrothers/constants';

import { isAdmin, isDealer } from '@Utils/RouteUtils';

export const useAdmin = () => {
  const isUserAdmin = !!useQuery(['isAdmin'], isAdmin).data;
  const isUserDealer = !!useQuery(['isDealer'], isDealer).data;

  let role;

  if (isUserAdmin) {
    role = Role.ADMIN;
  }

  if (isUserDealer) {
    role = Role.DEALER;
  }

  return {
    isAdmin: isUserAdmin,
    isDealer: isUserDealer,
    role,
  };
};
