import BLOG from './blog.json';
import BUILDINGTYPE from './buildingtype.json';
import COMPANY from './company.json';
import CUSTOMER from './customer.json';
import ENUM from './enum.json';
import FORMS from './forms.json';
import GLOBAL from './global.json';
import GROUP from './group.json';
import PRODUCT from './product.json';
import PROJECT from './project.json';
import USER from './user.json';

const data = {
  translation: {
    BLOG,
    BUILDINGTYPE,
    COMPANY,
    CUSTOMER,
    ENUM,
    FORMS,
    GLOBAL,
    GROUP,
    PRODUCT,
    PROJECT,
    USER,
  },
};

export default data;
