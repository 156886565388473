import cn from 'classnames';
import { get } from 'lodash';

import { NumberFieldProps } from './NumberField.props';

const hasNumberFormat = !!(
  typeof Intl === 'object' &&
  Intl &&
  typeof Intl.NumberFormat === 'function'
);

const NumberField = ({
  source,
  record,
  className,
  textAlign = 'right',
  options,
  ...props
}: NumberFieldProps) => {
  const value = get(record, source);

  return (
    <div className={cn([className, `text-${textAlign}`])} {...props}>
      {hasNumberFormat ? Number(value).toLocaleString('nl-NL', options) : value}
    </div>
  );
};

NumberField.displayName = 'NumberField';

export default NumberField;
